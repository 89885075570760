import React from "react";
import { motion } from "framer-motion";
export const Home = () => {
  return (
    <>
      <main>
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="main-content"
        >
          <h1 className="welocme-text">Welcome to 🎲 Casino</h1>
          <p>
            Discover thrilling casino games with big rewards! Spin the reels,
            roll the dice, and feel the excitement from anywhere. Join now and
            claim your welcome bonus!
          </p>
          <ul className="bullet-sec">
            <li>
              Your past does not define your future. You can always rewrite the
              story.
            </li>
            <li>
              Recovery is not for people who need it, it’s for people who want
              it.
            </li>
            <li>
              You can’t change what’s going on around you until you start
              changing what’s going on within you.
            </li>
          </ul>
        </motion.div>
      </main>
    </>
  );
};

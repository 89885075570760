import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Home } from "./page/home/home";
import { lazy, Suspense } from "react";
import Header from "./component/header/header";
import Lottie from "lottie-react";
import LottieAnimation from "./asset/loader/Animation - 1729167013086.json";
import { jwtDecode } from "jwt-decode";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FaWhatsapp } from "react-icons/fa";
import Sidebar from "./component/sidebar/sidebar";
const Block = lazy(() => import("./page/block/block"));
const Commission = lazy(() => import("./page/commission/commission"));
const Profit = lazy(() => import("./page/profit/profit"));
const Tips = lazy(() => import("./page/tip/tips"));
const About = lazy(() => import("./page/about/about"));
const Contact = lazy(() => import("./page/contact/contact"));
const Dashboard = lazy(() => import("./page/dashboard/dashboard"));
const People = lazy(() => import("./page/people/people"));
const Chat = lazy(() => import("./page/chat/chat"));
const Personal = lazy(() => import("./page/chat/personal/personal"));
const Admin = lazy(() => import("./page/admin/admin"));
const App = () => {
  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Lottie
              animationData={LottieAnimation}
              loop={true} // Set to false if you want the animation to play only once
              autoplay={true} // Set to false if you want to control the animation manually
              style={{ width: "300px" }}
            />
          </div>
        }
      >
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tips" element={<Tips />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route element={<ProtectRoutes />}>
            <Route path="/block" element={<Block />} />
            <Route path="/commission" element={<Commission />} />
            <Route path="/profit" element={<Profit />} />
            {/* <Route path="/people" element={<People />} /> */}
            {/* <Route path="/admin" element={<Admin />} /> */}
            <Route path="/chat" element={<Chat />}>
              <Route path=":id" element={<Personal />} />
            </Route>
          </Route>
          <Route element={<AdminRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/ablock" element={<Block />} />
            <Route path="/acommission" element={<Commission />} />
            <Route path="/aprofit" element={<Profit />} />
            <Route path="/people" element={<People />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/chat" element={<Chat />}>
              <Route path=":id" element={<Personal />} />
            </Route>
          </Route>
        </Routes>
        <ToastContainer />
      </Suspense>
      {/* <div className="sidebar-mob">
        <h1>This is rendered only on mobile</h1>
      </div> */}
      <a href={`https://wa.me/8807571005`} target="blank">
        <FaWhatsapp className="whatapp" />
      </a>
    </>
  );
};
export default App;

const ProtectRoutes = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const googleauth = queryParams.get("token");
  if (googleauth) {
    const googletoken = googleauth && jwtDecode(googleauth);
    localStorage.setItem("user", JSON.stringify(googletoken));
    localStorage.setItem("token", googleauth);
  }

  const token = localStorage.getItem("token") || googleauth;
  const user = JSON.stringify(localStorage.getItem("user"));
  if (!token || !user) {
    return <Navigate to="/" />;
  }
  return (
    <div className="private-area">
      <Sidebar />
      <Outlet />
    </div>
  );
};

const AdminRoutes = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const googleauth = queryParams.get("token");
  let googletoken;
  if (googleauth) {
    googletoken = googleauth && jwtDecode(googleauth);
    localStorage.setItem("user", JSON.stringify(googletoken));
    localStorage.setItem("token", googleauth);
  }

  const token = localStorage.getItem("token");
  const decodedToken = token && jwtDecode(token);

  if (decodedToken?.role === "admin" || googletoken?.role === "admin") {
    return (
      <div className="private-area">
        <Sidebar />
        <Outlet />
      </div>
    );
  }
  return <Navigate to="/" />;
};

import React, { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
import "./header.css";
import { motion } from "framer-motion";
import { NavLink, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/api";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";
import { FaGoogle } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mobile, seTMobile] = useState(false);
  const navigate = useNavigate();
  const toggleLogin = () => {
    setIsOpen(!isOpen);
  };
  const data = [
    {
      name: "home",
      link: "/",
      icon: "",
    },
    {
      name: "about",
      link: "/about",
      icon: "",
    },
    {
      name: "contact",
      link: "/contact",
      icon: "",
    },
    {
      name: "tips",
      link: "/tips",
      icon: "",
    },
  ];
  return (
    <header className="header">
      <div style={{ position: "relative" }} className="heading-section">
        <motion.h3
          // onClick={toggleSidebar}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          {" "}
          Online <span>casino</span>
        </motion.h3>
        <motion.p
          className="descrip"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Rehiblation, service, tips & tricks.
        </motion.p>
      </div>
      <nav className="desk-nav">
        {data.map((item, i) => {
          return (
            <NavLink
              to={item.link}
              className={({ isActive }) =>
                isActive ? "active" : "header-link"
              }
              key={i}
            >
              {item.name}
            </NavLink>
          );
        })}
      </nav>
      {localStorage.getItem("token") ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaHome
            className="dashborad-icon"
            onClick={() => navigate("/chat")}
          />
          <div className="mob-btn">
            {mobile ? (
              <IoMdClose onClick={() => seTMobile(!mobile)} />
            ) : (
              <RxHamburgerMenu onClick={() => seTMobile(!mobile)} />
            )}
          </div>

          <button
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              navigate("/");
            }}
            className="logout"
          >
            Logout
          </button>
        </div>
      ) : (
        <button onClick={toggleLogin} className="login">
          Login
        </button>
      )}
      {mobile && (
        <div className="sidebar-mob">
          <nav className="mob-nav">
            {data.map((item, i) => {
              return (
                <NavLink
                  to={item.link}
                  className={({ isActive }) =>
                    isActive ? "active" : "header-link"
                  }
                  key={i}
                  onClick={() => seTMobile(false)}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </nav>

          <button
            onClick={() => {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              navigate("/");
            }}
            className="logout-mob"
          >
            Logout
          </button>
        </div>
      )}

      {isOpen && <LoginPage isLoginOpen={isOpen} toggleLogin={toggleLogin} />}
    </header>
  );
};

export default Header;

const LoginPage = ({ isLoginOpen, toggleLogin }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [contentVisible, setContentVisible] = useState(true);

  const toggleSignUp = () => {
    // Hide content first, rotate and move forms, then show new content
    setContentVisible(false);
    setTimeout(() => {
      setIsSignUp(!isSignUp);
      setContentVisible(true);
    }, 500); // Matches the animation duration
  };

  const [user, setUser] = useState({
    email: "",
    name: "",
    cpass: "",
    pass: "",
  });
  const [verify, setVerify] = useState({
    email: "",
    otp: "",
    show: false,
  });
  const navigate = useNavigate();

  const handleVerify = () => {
    const { email, otp } = verify;
    AxiosInstance.post("/api/v1/user/verify-otp", {
      email,
      otp,
    })
      .then((res) => {
        if (res.data.token) {
          toast.success("Account is activated");
          localStorage.setItem("token", res.data.token);
          setVerify({
            email: "",
            otp: "",
            show: false,
          });
          toggleLogin();
          navigate("/block");
        }
      })
      .catch((e) => {
        if (e.code === "ERR_NETWORK") {
          toast.error("Server is unreachable. Please try again later.");
        } else {
          toast.error(e?.response?.data?.message || e?.message);
        }
      });
  };
  const handleLogin = () => {
    const { email, pass } = user;
    AxiosInstance.post("/api/v1/user/login", {
      email,
      password: pass,
    })
      .then((res) => {
        if (res.data.token) {
          toast.success("Login Successfully");
          localStorage.setItem("token", res.data.token);
          toggleLogin();
          navigate("/block");
        }
      })
      .catch((e) => {
        if (e.code === "ERR_NETWORK") {
          toast.error("Server is unreachable. Please try again later.");
        } else {
          toast.error(e?.response?.data?.message || e?.message);
        }
      });
  };

  const handleSignup = () => {
    AxiosInstance.post("/api/v1/user/signup", user)
      .then((res) => {
        if (res.data.success === true) {
          toast.info(res.data.message);
          setUser({
            email: "",
            name: "",
            cpass: "",
            pass: "",
          });
        } else {
          toast.info(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.error.code === 11000) {
          toast.error("Already email exist");
        } else {
          toast.error(e.message);
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setVerify({ ...verify, [name]: value });
  };
  const handleGoogleLogin = () => {
    console.log("google login");
    window.location.href =
      process.env.NODE_ENV === "development"
        ? "http://localhost:8000/auth/google"
        : "https://casino-game-server.onrender.com/auth/google";
    // window.open(
    //   "http://localhost:8000/auth/google",
    //   "Google Login",
    //   `width=500,height=600,top=${window.innerHeight / 2 - 300},left=${
    //     window.innerWidth / 2 - 250
    //   }`
    // );
  };
  return (
    <div className="login-page">
      <motion.div
        className="login-container"
        initial={{ x: "100%" }}
        animate={{ x: isLoginOpen ? 0 : "100%" }}
        transition={{ type: "spring", stiffness: 50 }}
      >
        <div className="form-container">
          <motion.div
            className={`login-content ${isSignUp ? "move-left" : "move-right"}`}
            initial={{ opacity: 1 }}
            animate={{ opacity: contentVisible ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            {!verify.show && (
              <>
                {!isSignUp ? (
                  <>
                    <motion.div
                      initial={{ rotateY: 0 }}
                      animate={{ rotateY: isSignUp ? 180 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h1>Login</h1>
                    </motion.div>
                    {/* <form> */}
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                    <input
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={user.pass}
                      onChange={(e) =>
                        setUser({ ...user, pass: e.target.value })
                      }
                    />
                    <button
                      type="submit"
                      onClick={() => handleLogin()}
                      className="login"
                    >
                      Login
                    </button>
                    {/* </form> */}
                    <p style={{ marginTop: "5px" }}>
                      New User?{" "}
                      <span
                        onClick={toggleSignUp}
                        style={{ color: "teal", fontWeight: "bold" }}
                      >
                        Sign Up
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <motion.div
                      initial={{ rotateY: 180 }}
                      animate={{ rotateY: isSignUp ? 360 : 180 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h1>Sign Up</h1>
                    </motion.div>
                    <input
                      name="name"
                      value={user.name}
                      placeholder="Name"
                      onChange={handleChange}
                    />
                    <input
                      name="email"
                      value={user.email}
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                    <input
                      name="pass"
                      value={user.pass}
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                    />
                    <input
                      name="cpass"
                      value={user.cpass}
                      type="password"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                    />
                    <button
                      type="submit"
                      className="signup"
                      onClick={() => handleSignup()}
                    >
                      Sign Up
                    </button>

                    <p style={{ marginTop: "10px" }}>
                      Already have an account?{" "}
                      <span
                        onClick={toggleSignUp}
                        style={{ color: "teal", fontWeight: "bold" }}
                      >
                        Login
                      </span>
                    </p>
                  </>
                )}
              </>
            )}

            {verify.show && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <p
                  style={{ textAlign: "right" }}
                  onClick={() => setVerify({ ...verify, show: false })}
                >
                  Back
                </p>
                <input
                  name="email"
                  value={verify.email}
                  placeholder="Email"
                  onChange={handleChange1}
                />
                <input
                  name="otp"
                  value={verify.otp}
                  placeholder="Otp"
                  onChange={handleChange1}
                />
                <button
                  type="submit"
                  className="signup"
                  onClick={() => handleVerify()}
                >
                  verify
                </button>
              </motion.div>
            )}

            <button onClick={() => handleGoogleLogin()} className="google-btn">
              <FaGoogle className="google-icon" />
              Connect with Google
            </button>
            {!verify.show && (
              <p
                style={{ textAlign: "center" }}
                onClick={() => setVerify({ ...verify, show: true })}
              >
                Verify your account
              </p>
            )}
          </motion.div>
        </div>
      </motion.div>

      <motion.div
        className="overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }} // This defines how the overlay will disappear
        transition={{ duration: 1 }} // Adjust the duration to control the fade-out speed
        onClick={toggleLogin}
      />
    </div>
  );
};
